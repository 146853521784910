import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminFooter from './AdminFooter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { isAuthenticated } from '../middleware/admin_auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function AdminIndividualMicrositeEdit() {

    useEffect(() => {
        // Check if the user is authenticated
        if (!isAuthenticated()) {
          // Redirect to the login page
          window.location.href = '/guru';
        }
    }, []);

    const toolbarOptions = [
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['clean'],
      ];
    
    const { id } = useParams();
    const [micrositeData, setMicrositeData] = useState({});
    const { 
        name
        ,content
        ,scontent
        ,location
        ,place
        ,image
        ,thumb
        ,banner
        ,template
        ,tem_id
        ,title
        ,group_id
        ,domain
        ,analytics
        ,webmaster
        ,url
        ,keywords
        ,description
        ,pooja_timing
        ,metakey_gallery
        ,metadesc_gallery
        ,metakey_greetings
        ,metadesc_greetings
        ,metakey_flight
        ,metadesc_flight
        ,metakey_hotel
        ,metadesc_hotel
        ,metakey_hotel_booking
        ,metadesc_hotel_booking
        ,metakey_other
        ,metadesc_other
        ,metakey_contact
        ,metadesc_contact
        ,metakey_tour
        ,metadesc_tour
        ,products
        ,p1
        ,p2
        ,p3
        ,p4
        ,p5
        ,ad_img
        ,ad_text
        ,ad_mode
        ,ad_img_mode
        ,ad_text_mode
        ,ad_l_img
        ,ad_l_mode
        ,ad_r_img
        ,ad_r_mode
        ,ad_url
        ,ad_l_url
        ,ad_r_url
        ,footer_content
        ,created
        ,modified
    } = micrositeData;

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite/${id}`;
    console.log("api_url", apiUrl);

    useEffect(() => {
        fetch(apiUrl)
        .then(response => response.json())
        .then(data => setMicrositeData(data))
        .catch(error => console.error(error));
    }, []);


    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    

    const handleSaveDetails = () => {
        const updatedMicrositeData = {
            ...micrositeData,
            name
            ,content
            ,scontent
            ,location
            ,place
            ,image
            ,thumb
            ,banner
            ,template
            ,tem_id
            ,title
            ,group_id
            ,domain
            ,analytics
            ,webmaster
            ,url
            ,keywords
            ,description
            ,pooja_timing
            ,metakey_gallery
            ,metadesc_gallery
            ,metakey_greetings
            ,metadesc_greetings
            ,metakey_flight
            ,metadesc_flight
            ,metakey_hotel
            ,metadesc_hotel
            ,metakey_hotel_booking
            ,metadesc_hotel_booking
            ,metakey_other
            ,metadesc_other
            ,metakey_contact
            ,metadesc_contact
            ,metakey_tour
            ,metadesc_tour
            ,products
            ,p1
            ,p2
            ,p3
            ,p4
            ,p5
            ,ad_img
            ,ad_text
            ,ad_mode
            ,ad_img_mode
            ,ad_text_mode
            ,ad_l_img
            ,ad_l_mode
            ,ad_r_img
            ,ad_r_mode
            ,ad_url
            ,ad_l_url
            ,ad_r_url
            ,footer_content
            ,created
            ,modified            
        };
        console.log("updatedMicrositeData: ",JSON.stringify(updatedMicrositeData));
        const micrositeUpdate1 = `${process.env.REACT_APP_API_ENDPOINT}admin_microsite_update`;
        fetch(micrositeUpdate1, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedMicrositeData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setModalMessage('Data saved successfully!');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setModalMessage('There was an error while saving data');
                setShowModal(true);
                setTimeout(() => setShowModal(false), 5000);
            });
    };

    // 3rd Tab
    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const cropperRef = useRef();
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setCroppedImage(null);
    };

    const handleCrop = () => {
        if (!cropperRef.current || !cropperRef.current.cropper) {
            setError("Cropper reference not properly defined");
            return;
        }
        const canvas = cropperRef.current.cropper.getCroppedCanvas({ width: 250, height: 250 });
        setCroppedImage(canvas.toDataURL("image/jpeg", 0.8));
    };

    const handleUpload = async () => {
        if (!croppedImage) {
            setError("Please crop an image first");
            return;
        }
        setLoading(true);
        setStatus(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'thumb');
        formData.append("image", dataURItoBlob(croppedImage), `${Date.now()}-${selectedFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_microsite_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessage("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessage("");
                }, 5000);
                setSelectedFile(null);
                setCroppedImage(null);
                window.location.reload();
            } else {
                setError("There was an error uploading the image");
            }
        } catch (error) {
            setError("There was an error uploading the image");
            setStatus("error");
        }
        setLoading(false);
    };    

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    const handleImageLoad = () => {
        if (cropperRef.current && !cropperRef.current.cropper) {
            const cropper = new Cropper(cropperRef.current, {
                aspectRatio: 1 / 1,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setError(null);
                },
            });
            cropperRef.current.cropper = cropper;
        }
    };
    // Thumbs Retrieval
    const [thumbImages, setThumbImages] = useState([]);

    useEffect(() => {
      const imageAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=temple_slides&id=${id}`;
      console.log("imageAPIURL: ", imageAPIURL);
      fetch(imageAPIURL)
        .then(response => response.json())
        .then(data => setThumbImages(data))
        .catch(error => console.error(error));
    }, []);

    function handleImageDelete(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=thumb&site_category=microsite`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(error => {
              console.error(error);
              alert("Failed to delete image");
            });
        }
      }


    //Gallery items
    const [selectedGalleryFile, setSelectedGalleryFile] = useState(null);
    const [croppedGalleryImage, setCroppedGalleryImage] = useState(null);
    const [errorGallery, setErrorGallery] = useState(null);
    const [loadingGallery, setLoadingGallery] = useState(false);
    const [statusGallery, setStatusGallery] = useState(null);
    const cropperRefGallery = useRef();
    const [successMessageGallery, setSuccessMessageGallery] = useState("");

    const handleFileChangeGallery = (event) => {
        setSelectedGalleryFile(event.target.files[0]);
        setCroppedGalleryImage(null);
    };

    const handleCropGallery = () => {
        if (!cropperRefGallery.current || !cropperRefGallery.current.cropper) {
            setErrorGallery("Cropper reference not properly defined");
            return;
        }
        const canvasGallery = cropperRefGallery.current.cropper.getCroppedCanvas({ width: 1200, height: 400 });
        setCroppedGalleryImage(canvasGallery.toDataURL("image/jpeg", 0.8));
    };

    const handleUploadGallery = async () => {
        if (!croppedGalleryImage) {
            setErrorGallery("Please crop an image first");
            return;
        }
        setLoadingGallery(true);
        setStatusGallery(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'gallery');
        formData.append("image", dataURItoBlob(croppedGalleryImage), `${Date.now()}-${selectedGalleryFile.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_microsite_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessageGallery("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessageGallery("");
                }, 5000);
                setSelectedGalleryFile(null);
                setCroppedGalleryImage(null);
                window.location.reload();
            } else {
                setErrorGallery("There was an error uploading Gallery image");
            }
        } catch (errorGallery) {
            setErrorGallery("There was an error uploading Gallery image");
            setStatusGallery("errorGallery");
        }
        setLoadingGallery(false);
    };

    const handleImageLoadGallery = () => {
        if (cropperRefGallery.current && !cropperRefGallery.current.cropper) {
            const cropper = new Cropper(cropperRefGallery.current, {
                aspectRatio: 3 / 1,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setErrorGallery(null);
                },
            });
            cropperRefGallery.current.cropper = cropper;
        }
    };



    //Contact Image item
    const [selectedGalleryFileContact, setSelectedGalleryFileContact] = useState(null);
    const [croppedGalleryImageContact, setCroppedGalleryImageContact] = useState(null);
    const [errorGalleryContact, setErrorGalleryContact] = useState(null);
    const [loadingGalleryContact, setLoadingGalleryContact] = useState(false);
    const [statusGalleryContact, setStatusGalleryContact] = useState(null);
    const cropperRefGalleryContact = useRef();
    const [successMessageGalleryContact, setSuccessMessageGalleryContact] = useState("");

    const handleFileChangeGalleryContact = (event) => {
        setSelectedGalleryFileContact(event.target.files[0]);
        setCroppedGalleryImageContact(null);
    };

    const handleCropGalleryContact = () => {
        if (!cropperRefGalleryContact.current || !cropperRefGalleryContact.current.cropper) {
            setErrorGalleryContact("Cropper reference not properly defined");
            return;
        }
        const canvasGalleryContact = cropperRefGalleryContact.current.cropper.getCroppedCanvas({ width: 1200, height: 150 });
        setCroppedGalleryImageContact(canvasGalleryContact.toDataURL("image/jpeg", 1));
    };

    const handleUploadGalleryContact = async () => {
        if (!croppedGalleryImageContact) {
            setErrorGalleryContact("Please crop an image first");
            return;
        }
        setLoadingGalleryContact(true);
        setStatusGalleryContact(null);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('image_type', 'gallery');
        formData.append("image", dataURItoBlob(croppedGalleryImageContact), `${Date.now()}-${selectedGalleryFileContact.name}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}admin_microsite_contact_image_upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setSuccessMessageGalleryContact("Image uploaded successfully");
                setTimeout(() => {
                  setSuccessMessageGalleryContact("");
                }, 5000);
                setSelectedGalleryFileContact(null);
                setCroppedGalleryImageContact(null);
                window.location.reload();
            } else {
                setErrorGalleryContact("There was an error uploading Gallery image");
            }
        } catch (errorGalleryContact) {
            setErrorGalleryContact("There was an error uploading Gallery image");
            setStatusGalleryContact("errorGallery");
        }
        setLoadingGalleryContact(false);
    };

    const handleImageLoadGalleryContact = () => {
        if (cropperRefGalleryContact.current && !cropperRefGalleryContact.current.cropper) {
            const cropper = new Cropper(cropperRefGalleryContact.current, {
                aspectRatio: 8 / 1,
                viewMode: 1,
                autoCropArea: 1,
                crop: () => {
                    setErrorGalleryContact(null);
                },
            });
            cropperRefGalleryContact.current.cropper = cropper;
        }
    };



    
    // Gallery Retrieval
    const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
    const [captionInput, setCaptionInput] = useState('');

    useEffect(() => {
        const imageAPIURLGallery = `${process.env.REACT_APP_API_ENDPOINT}microsite_temple_slideshows/${id}?admin=1`;
        console.log("imageAPIURLGallery: ",imageAPIURLGallery);
        fetch(imageAPIURLGallery)
          .then(response => response.json())
          .then(data => setThumbImagesGallery(data))
          .catch(errorGallery => console.errorGallery(errorGallery));
    }, []);
      
    const handleCaptionChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].name = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleFeatureIdChange = (e, index) => {
        const updatedGallery = [...thumbImagesGallery];
        updatedGallery[index].feature_id = e.target.value;
        setThumbImagesGallery(updatedGallery);
      };
      
      const handleSaveCaption = (index) => {
        const image = thumbImagesGallery[index];
        const updateCaptionURL = `${process.env.REACT_APP_API_ENDPOINT}update_caption`;
        fetch(updateCaptionURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: image.id,
            caption: image.name,
            feature_id: image.feature_id,
            cat: 'microsite',
          }),
        })
          .then(response => response.json())
          .then(data => {
            // Handle the response, show success message, update status, etc.
            console.log('Caption saved:', data);
            alert("Caption Saved Successfully!");
          })
          .catch(error => {
            // Handle the error, show error message, update status, etc.
            console.error('Error saving caption:', error);
            alert("There was a problem, contact your dev team!");
          });
      };

    function handleImageDeleteGallery(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
          // Call API to delete the image
          fetch(`${process.env.REACT_APP_API_ENDPOINT}image_delete?id=${id}&image_type=gallery&site_category=microsite`)
            .then(response => {
              if (!response.ok) {
                throw new Error("Failed to delete image");
              }
              // Reload the page to refresh the images
              window.location.reload();
            })
            .catch(errorGallery => {
              console.errorGallery(errorGallery);
              alert("Failed to delete image");
            });
        }
      } 


      const [templeArticles, setTempleArticles] = useState([]);
    
      useEffect(() => {
        // Fetching the data for the select options
        fetch(`${process.env.REACT_APP_API_ENDPOINT}temple_articles/1?cat=all&temple_id=${id}`)
          .then((response) => response.json())
          .then((data) => setTempleArticles(data))
          .catch((error) => console.error('Error fetching temple articles:', error));
      }, []); // This empty dependency array means this effect will run once when the component mounts

      console.log("microdata 1",micrositeData.p1);
      console.log("microdata 5",micrositeData.p5);


    
    
    // Second Tab

    const { keyword, pageId } = useParams();
    const [micrositeListings, setMicrositeListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Define totalPages variable

    const searchLocation = useLocation();
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState(0);

    const [searchValue, setSearchValue] = useState([]);

    useEffect(() => {
        let page = pageId ? parseInt(pageId) : currentPage; // Use the value from the URL if present
        setCurrentPage(page);
      
        const searchParams = new URLSearchParams(searchLocation.search);
      
        if (keyword) {
          searchParams.set('keyword', keyword);
        }
  
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_article_listings/${id}?noFilter=1&page=${page}&${searchParams.toString()}&admin=1`;
        console.log("apiUrl: ", apiUrl);      
      
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setMicrositeListings(data);
              setTotalCount(data.length > 0 ? data[0].totalCount : 0);
              const totalPages = Math.ceil(data[0].totalCount / 20);
              setTotalPages(totalPages);
          })
          .catch((error) => console.log(error));
    }, [id, pageId, currentPage, keyword, totalCount]);
  
    const handlePageClick = (page) => {
        setCurrentPage(page);
      
        const path = searchLocation.pathname;
        const currentQueryParams = new URLSearchParams(searchLocation.search);
        if(searchValue){
            currentQueryParams.set('keyword', searchValue); // Append the keyword to the URL
        }
      
        let newPath = path;
      
        if (path.includes('/page/')) {
          newPath = path.replace(/\/page\/\d+/, `/page/${page}`);
        } else {
          newPath = `${path}/page/${page}`;
        }
      
        const newURL = `${newPath}?${currentQueryParams.toString()}`;
        navigate(newURL);
      
        // Scroll to the top of the page
        window.scrollTo({
          top: 200,
          behavior: 'smooth',
        });
    };      


    const appendFilterToURL = (filterType, value) => {
        const searchParams = new URLSearchParams(searchLocation.search);
      
        if (filterType === 'page') {
          searchParams.set(filterType, value);
        } else {
          if (searchParams.has(filterType)) {
            searchParams.delete(filterType);
          }
      
          searchParams.set(filterType, value);
        }
      
        const newURL = `${searchLocation.pathname}?${searchParams.toString()}`;
        navigate(newURL);
    };
    
    function updateURLParameter(url, param, value) {
        const searchParams = new URLSearchParams(searchLocation.search);
        searchParams.delete(param);
        searchParams.append(param, value);
        return `${url.split('?')[0]}?${searchParams.toString()}`;
    } 


    const [searchResults, setSearchResults] = useState([]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';
      
        if (isBackspace) {
          setSearchValue('');
          window.location.href = `/admin/micro/editsettings/${id}/page/1`;
        } else {
          const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_article_listings/${id}?noFilter=1&page=${currentPage}&keyword=${inputValue}`;
          console.log("apiSearchURL:", apiUrl);
          fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
              setMicrositeListings(data);
            })
            .catch(error => console.error(error));
          setSearchValue(inputValue);
          //console.log("searchValue: ", searchValue);
        }
    };
    

    return (
        <div class="dashboard__main">

            {/* Modal for success */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            <div class="dashboard__content bg-light-2">
                <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                <div class="col-auto">

                    <h1 class="text-30 lh-14 fw-600">Edit Microsite</h1>

                </div>
                    {/*
                    <div class="col-auto">
                        <a target="_blank" style={{float:'left'}} href={`/admin/microsite/room/types/${id}`} 
                        class="button h-50 px-24 -blue-1 bg-dark-3 text-white pull-left mr-10">
                            Room Types <div class="icon-arrow-top-right ml-15"></div>
                        </a>
                        <a target="_blank" href={`/microsite/${name ? name.toLowerCase().replace(/\s+/g, '-') : ''}/${id}`} 
                        class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            View Page <div class="icon-arrow-top-right ml-15"></div>
                        </a>
                    </div>
                    */}
                </div>


                <div class="py-30 px-30 rounded-4 bg-white shadow-3">
                <div class="tabs -underline-2 js-tabs">
                    <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">

                        <div class="col-auto">
                            <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active" data-tab-target=".-tab-item-1">1. Content</button>
                        </div>

                        <div class="col-auto">
                            <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-2">2. Articles</button>
                        </div>

                        <div class="col-auto">
                            <button class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " data-tab-target=".-tab-item-3">3. Slideshow</button>
                        </div>

                    </div>

                    <div class="tabs__content pt-30 js-tabs-content">
                    <div class="tabs__pane -tab-item-1 is-tab-el-active">
                        <div class="col-xl-10">
                            <div class="text-18 fw-500 mb-10">Tour Microsite Content</div>
                            <div class="row x-gap-20 y-gap-20">

                                    <div className="col-12">
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            value={micrositeData.name}
                                            name="name"
                                            onChange={(event) =>
                                                setMicrositeData((prevState) => ({
                                                ...prevState,
                                                name: event.target.value,
                                                }))
                                            }
                                            required
                                            />
                                            <label className="lh-1 text-16 text-light-1">Microsite Name</label>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                        <div className="fw-500">Content</div>
                                        <div className="form-input" style={{ maxHeight: 300 }}>
                                            <ReactQuill
                                                value={content}
                                                onChange={(value) => setMicrositeData(prevState => ({...prevState, content: value}))}
                                                modules={{ toolbar: toolbarOptions }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                        <div className="fw-500">Short Description</div>
                                        <div className="form-input" style={{ maxHeight: 300 }}>
                                            <ReactQuill
                                                value={scontent}
                                                onChange={(value) => setMicrositeData(prevState => ({...prevState, scontent: value}))}
                                                modules={{ toolbar: toolbarOptions }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            value={micrositeData.place}
                                            name="place"
                                            onChange={(event) =>
                                                setMicrositeData((prevState) => ({
                                                ...prevState,
                                                place: event.target.value,
                                                }))
                                            }
                                            required
                                            />
                                            <label className="lh-1 text-16 text-light-1">Place</label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            value={micrositeData.title}
                                            name="title"
                                            onChange={(event) =>
                                                setMicrositeData((prevState) => ({
                                                ...prevState,
                                                title: event.target.value,
                                                }))
                                            }
                                            required
                                            />
                                            <label className="lh-1 text-16 text-light-1">Page Title</label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            value={micrositeData.domain}
                                            name="domain"
                                            onChange={(event) =>
                                                setMicrositeData((prevState) => ({
                                                ...prevState,
                                                domain: event.target.value,
                                                }))
                                            }
                                            required
                                            />
                                            <label className="lh-1 text-16 text-light-1">Domain Name</label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            value={micrositeData.analytics}
                                            name="analytics"
                                            onChange={(event) =>
                                                setMicrositeData((prevState) => ({
                                                ...prevState,
                                                analytics: event.target.value,
                                                }))
                                            }
                                            required
                                            />
                                            <label className="lh-1 text-16 text-light-1">GA Code of the Microsite</label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            value={micrositeData.webmaster}
                                            name="webmaster"
                                            onChange={(event) =>
                                                setMicrositeData((prevState) => ({
                                                ...prevState,
                                                webmaster: event.target.value,
                                                }))
                                            }
                                            required
                                            />
                                            <label className="lh-1 text-16 text-light-1">Google Console code of the Microsite</label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-input">
                                            <input
                                            type="text"
                                            value={micrositeData.pooja_timing}
                                            name="pooja_timing"
                                            onChange={(event) =>
                                                setMicrositeData((prevState) => ({
                                                ...prevState,
                                                pooja_timing: event.target.value,
                                                }))
                                            }
                                            required
                                            />
                                            <label className="lh-1 text-16 text-light-1">Pooja Timing</label>
                                        </div>
                                    </div>

                                    <div class="col-12 room_input">
                                        <div className='border-light rounded-4 admin_dropdown_inner'>
                                            <h4 class="text-15 fw-500 ls-2 lh-16">Home Priority Article 1</h4>
                                            <div class="text-15 text-light-1 ls-2 lh-16">

                                                <select
                                                value={micrositeData.p1}
                                                onChange={(event) =>
                                                    setMicrositeData((prevState) => ({
                                                    ...prevState,
                                                    p1: event.target.value,
                                                    }))
                                                }
                                                >
                                                <option value="" disabled>Select an option</option>
                                                {templeArticles.map((option, index) =>
                                                    option.p1 !== "" ? (
                                                    <option key={index} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                    ) : null
                                                )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 room_input">
                                        <div className='border-light rounded-4 admin_dropdown_inner'>
                                            <h4 class="text-15 fw-500 ls-2 lh-16">Home Priority Article 2</h4>
                                            <div class="text-15 text-light-1 ls-2 lh-16">

                                                <select
                                                value={micrositeData.p2}
                                                onChange={(event) =>
                                                    setMicrositeData((prevState) => ({
                                                    ...prevState,
                                                    p2: event.target.value,
                                                    }))
                                                }
                                                >
                                                <option value="" disabled>Select an option</option>
                                                {templeArticles.map((option, index) =>
                                                    option.p2 !== "" ? (
                                                    <option key={index} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                    ) : null
                                                )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 room_input">
                                        <div className='border-light rounded-4 admin_dropdown_inner'>
                                            <h4 class="text-15 fw-500 ls-2 lh-16">Home Priority Article 3</h4>
                                            <div class="text-15 text-light-1 ls-2 lh-16">

                                                <select
                                                value={micrositeData.p3}
                                                onChange={(event) =>
                                                    setMicrositeData((prevState) => ({
                                                    ...prevState,
                                                    p3: event.target.value,
                                                    }))
                                                }
                                                >
                                                <option value="" disabled>Select an option</option>
                                                {templeArticles.map((option, index) =>
                                                    option.p3 !== "" ? (
                                                    <option key={index} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                    ) : null
                                                )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 room_input">
                                        <div className='border-light rounded-4 admin_dropdown_inner'>
                                            <h4 class="text-15 fw-500 ls-2 lh-16">Disclaimer Article</h4>
                                            <div class="text-15 text-light-1 ls-2 lh-16">

                                                <select
                                                value={micrositeData.p5}
                                                onChange={(event) =>
                                                    setMicrositeData((prevState) => ({
                                                    ...prevState,
                                                    p5: event.target.value,
                                                    }))
                                                }
                                                >
                                                <option value="" disabled>Select an option</option>
                                                {templeArticles.map((option, index) =>
                                                    option.p5 !== "" ? (
                                                    <option key={index} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                    ) : null
                                                )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-50">
                                        <div className="fw-500">Footer Content</div>
                                        <div className="form-input" style={{ maxHeight: 300 }}>
                                            <ReactQuill
                                                value={footer_content}
                                                onChange={(value) => setMicrositeData(prevState => ({...prevState, footer_content: value}))}
                                                modules={{ toolbar: toolbarOptions }}
                                            />
                                        </div>
                                    </div>
                            </div>

                        </div>

                        <div className='col-md-12'>
                            <div onClick={() => handleSaveDetails(id)} class="d-inline-block pt-30" style={{float:'right'}}>
                                <a href="#" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Save Details <img className='save_icon' src={`/assets/img/general/save-icon.png`} />
                                </a>
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>

                    </div>
                    
                    
                    <div class="tabs__pane -tab-item-2">
                        <div class="col-xl-12">
                            <div class="row y-gap-20 justify-between items-end pb-30 lg:pb-40 md:pb-32">
                                <div class="col-auto">
                    
                                    <h1 class="text-30 lh-14 fw-600">Articles</h1>
                    
                                </div>

                                <div class="col-auto">
                                    <div class="w-230 single-field relative d-flex items-center" style={{borderStyle:'solid', borderWidth:'1px', borderColor:'#ccc'}}>
                                        <input
                                        className="pl-50 bg-white text-dark-1 h-50 rounded-8"
                                        type="text"
                                        placeholder="Search"
                                        onChange={handleInputChange}
                                        value={searchValue}
                                        />
                                        <ul className="autocomplete-results">
                                        {searchResults.map(result => (
                                            <li key={result.name}>{result.name}</li>
                                        ))}
                                        </ul>
                                        <button class="absolute d-flex items-center h-full">
                                            <i class="icon-search text-20 px-15 text-dark-1"></i>
                                        </button>
                                    </div>
                                </div>
                                
                                <div class="col-auto">
                                    <a href={`/admin/micro/article/create/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                        Add New Article / Tour<div class="icon-arrow-top-right ml-15"></div>
                                    </a>
                                </div>
                                
                            </div>
                    
                    
                            <div class="rounded-4 bg-white shadow-3">
                    
                    
                                    <div class="overflow-scroll scroll-bar-1">
                                        <table class="table-4 -border-bottom col-12">
                                        <thead class="bg-light-2">
                                            <tr>
                                            <th>Article ID</th>
                                            <th>Article Name</th>
                                            <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                    
                                        {micrositeListings.map(microsite => (
                                            <tr key={microsite.id}>
                                                <td className="text-blue-1 fw-500">
                                                    {microsite.id}
                                                </td>
                                                <td className="text-blue-1 fw-500">
                                                    {microsite.title}
                                                </td>
                                                <td>
                                                    <div className="row x-gap-10 y-gap-10 items-center">
                                                        <div className="col-auto">
                                                            <a target="_blank" href={`/admin/micro/article/edit/${microsite.id}`} >
                                                                <button title="Edit" className="flex-center bg-light-2 rounded-4 size-35">
                                                                <i className="icon-edit text-16 text-light-1"></i>
                                                                </button>
                                                            </a>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button
                                                                title="Delete"
                                                                className="flex-center bg-light-2 rounded-4 size-35"
                                                                onClick={() => {
                                                                    if (window.confirm("Are you sure you want to delete this article?")) {
                                                                        window.location.href = `/admin/micro/article/delete/${microsite.id}`;
                                                                    }
                                                                }}
                                                            >
                                                                <i className="icon-trash-2 text-16 text-light-1"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                    
                                        </tbody>
                                        </table>
                                    </div>

                                <div class="pt-30">
                                    <div class="row justify-between">
                                        <div class="col-auto">

                                        </div>

                                        <div class="col-auto">
                                            <div className="row x-gap-20 y-gap-20 items-center pagination">
                                            {currentPage !== 1 && (
                                                <div
                                                    className="col-auto pagination_numbers"
                                                    onClick={() => handlePageClick(1)}
                                                >
                                                    <div className="size-40 flex-center rounded-full">1</div>
                                                </div>
                                                )}
                                                {currentPage > 3 && (
                                                <div className="col-auto">
                                                    <div className="size-40 flex-center rounded-full">...</div>
                                                </div>
                                                )}
                                                {Array.from({ length: totalPages }, (_, index) => {
                                                const page = index + 1;
                                                if (page === totalPages) return null; // Skip the last page number
                                                if (
                                                    page < currentPage - 1 ||
                                                    page > currentPage + 1 ||
                                                    (currentPage === 2 && page === 1) // Skip displaying 1 when on the second page
                                                )
                                                    return null;
                                                return (
                                                    <div
                                                    key={index}
                                                    className={`col-auto pagination_numbers ${
                                                        page === currentPage ? "bg-dark-1 text-white" : ""
                                                    }`}
                                                    onClick={() => handlePageClick(page)}
                                                    >
                                                    <div className="size-40 flex-center rounded-full">{page}</div>
                                                    </div>
                                                );
                                                })}
                                                {currentPage < totalPages - 2 && (
                                                <div className="col-auto">
                                                    <div className="size-40 flex-center rounded-full">...</div>
                                                </div>
                                                )}
                                                {currentPage !== totalPages && (
                                                <div
                                                    className="col-auto pagination_numbers"
                                                    onClick={() => handlePageClick(totalPages)}
                                                >
                                                    <div className="size-40 flex-center rounded-full">{totalPages}</div>
                                                </div>
                                                )}
                                            </div>
                                        </div>

                                        <div class="col-auto">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    
                    <div class="tabs__pane -tab-item-3">
                        <div class="col-xl-12 col-lg-12">
                            <div class="col-12">
                                <div>
                                    <div class="fw-500">Upload New Slideshow Images (These would be 4:2 ratio, 1200 x 400 px recommended)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChangeGallery} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedGalleryFile && (
                                        <div>
                                            <img onLoad={handleImageLoadGallery} ref={cropperRefGallery} src={URL.createObjectURL(selectedGalleryFile)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCropGallery}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedGalleryImage && (
                                        <div>
                                            <img src={croppedGalleryImage} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedGalleryImage(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUploadGallery} disabled={loadingGallery}>{loadingGallery ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessageGallery && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessageGallery}
                                            </div>
                                            )}

                                            {errorGallery && <div class="alert alert-danger">{errorGallery}</div>}
                                            {statusGallery && <div class="alert alert-success">{statusGallery}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30">
                                    <div class="fw-500">Existing Slideshow Images</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">
                                    {thumbImagesGallery.length > 0 ? (
                                        thumbImagesGallery.map((image, index) => (
                                            <div className="col-auto" key={index}>
                                            <div className="d-flex ratio ratio-2:1 w-200" style={{ marginBottom: -15 }}>
                                                <img
                                                style={{ height: 'unset' }}
                                                src={
                                                    image.image.includes('aim_')
                                                    ? `/img/slideshow/${image.image}`
                                                    : `${process.env.REACT_APP_API_ENDPOINT}uploads/microsite/${image.image}`
                                                }
                                                alt="image"
                                                className="img-ratio rounded-4"
                                                />
                                                <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    className="size-40 bg-white rounded-4"
                                                    onClick={() => handleImageDeleteGallery(image.id)}
                                                >
                                                    <i className="icon-trash text-16 image_trash_icon"></i>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        ))
                                        ) : (
                                        <p>No Images Found</p>
                                    )}

                                    </div>
                                </div>

                            </div>
                                
                            <hr />

                            <div class="col-12">
                                <div>
                                    <div class="fw-500">Upload New Contact Image (This would be 1200 x 150 px recommended)</div>

                                    <div class="row x-gap-20 y-gap-20 pt-15">

                                    <div class="col-md-4">
                                        <div class="mb-3">
                                        <input class="form-control" type="file" id="inputFile" accept="image/*" onChange={handleFileChangeGalleryContact} />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        {selectedGalleryFileContact && (
                                        <div>
                                            <img onLoad={handleImageLoadGalleryContact} ref={cropperRefGalleryContact} src={URL.createObjectURL(selectedGalleryFileContact)} alt="Crop preview" />
                                            <button style={{width:'100%'}} class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleCropGalleryContact}>Crop Image</button>
                                        </div>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        {croppedGalleryImageContact && (
                                        <div>
                                            <img src={croppedGalleryImageContact} alt="Cropped image preview" />
                                            <div class="d-grid gap-2">
                                                <button class="button -md -blue-1 bg-blue-1-05 text-blue-1 mt-20" onClick={() => setCroppedGalleryImageContact(null)}>Reset</button>
                                                <button class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-20" onClick={handleUploadGalleryContact} disabled={loadingGalleryContact}>{loadingGalleryContact ? "Uploading..." : "Upload"}</button>
                                            </div>

                                            {successMessageGalleryContact && (
                                            <div class="alert alert-success" role="alert">
                                                {successMessageGalleryContact}
                                            </div>
                                            )}

                                            {errorGalleryContact && <div class="alert alert-danger">{errorGalleryContact}</div>}
                                            {statusGalleryContact && <div class="alert alert-success">{statusGalleryContact}</div>}
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                <div class="mt-30">
                                    <div class="fw-500">Existing Contact Image</div>

                                    <img
                                        style={{ height: 'unset',
                                            position: 'relative'
                                         }}
                                        src={`${process.env.REACT_APP_API_ENDPOINT}uploads/microsite/banners/${micrositeData.ad_img}`}
                                        alt="image"
                                        className="img-ratio rounded-4"
                                        />

                                </div>

                            </div>
                        </div>
                    </div>

                    </div>
                    
                </div>
                </div>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AdminIndividualMicrositeEdit;